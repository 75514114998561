.login {
  .model {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .login-form {
      padding: 6rem;
      box-shadow: 0px 2px 13px 0px rgba(228, 228, 228, 0.6);
      border-radius: 5px;
      background-color: #fff;
      width: 35rem;

      .login-form-button {
        width: 100%;
      }
    }
  }
}
.auth-main::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 400px;
  height: 100%;
  z-index: -1;
  background: #191f28;
}
@media screen and (max-width: 640px) {
  .auth-main::before {
    width: 100%;
  }
}
