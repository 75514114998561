.app {
  font-size: 1.4rem;
  font-weight: 400;
  @media screen and (max-width: 960px) {
    font-size: 1.4rem;
  }
  .aside {
    // height: 100vh;
    overflow-y: auto;
    .logo {
      height: 4.3rem;
      margin: 1rem;
      box-sizing: border-box;
      border-radius: 1rem;
      padding: 0 1rem;
      text-align: center;
      img {
        height: 85%;
      }
    }
  }

  .header {
    background-color: #fff;
    padding: 0 1.5rem;
    display: flex;
    justify-content: space-between;
    .right {
      display: flex;
      align-items: center;
    }
  }

  .content {
    padding: 10px 18px;
    width: auto !important;
  }

  .footer {
    text-align: center;
  }
}
.ant-descriptions-item-label {
  font-weight: 500;
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bg-login-top {
  background-size: cover !important;
  background-position: center !important;
}
.bg-login-bottom {
  background-size: cover !important;
  background-position: bottom !important;
}
.transparent-top {
  height: 50px;
  width: 100%;
  top: 0;
  background: #000;
  opacity: 0.5;
  position: absolute;
}
.logo-img {
  height: 40px;
  margin: 3px;
  margin-left: 12px;
}
