::-webkit-scrollbar {
  width: 5px;
  background-color: #fff;
}
::-webkit-scrollbar:horizontal {
  height: 5px;
  background-color: #fff;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ccc;
}
iframe {
  pointer-events: none;
}
.base-style {
  background-color: #fff;
  margin-bottom: 1rem;
  padding: 1.5rem;
  border-radius: 5px;
  box-shadow: 0px 2px 13px 0px rgba(228, 228, 228, 0.6);
}

.b-top-dark {
  border-top: 1px solid #dadada;
}
.b-right-dark {
  //border-right: 1px solid #dadada;
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
}
.b-bottom-dark {
  border-bottom: 1px solid #dadada;
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 0px !important;
}
span.anticon.anticon-pause-circle {
  font-size: 15px;
}
.ant-tag > .MuiSvgIcon-root {
  font-size: 14px;
  margin-right: 4px;
  margin-bottom: -1px;
  padding-top: 2px;
}
.fullscreen {
  overflow-y: scroll;
  background-color: #fff;
}
.fullscreen-icon {
  top: 18px;
  right: 18px;
  position: absolute !important;
}
.b-shadow {
  box-shadow: 2px 8px 8px 0 rgba(29, 35, 41, 0.05);
}
audio {
  filter: sepia(20%) saturate(70%) grayscale(1) contrast(99%) invert(12%);
  width: 107px;
  height: 25px;
}
.header-icon {
  height: 25px;
}
.ant-back-top {
  left: 285px;
  right: unset;
}
.ant-back-top-content {
  border-radius: 0px !important;
}
.ant-table-title {
  color: #ff8800;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  background: #fff7e6;
  text-transform: capitalize;
}
.user {
  padding-right: 6px;
  padding-top: 0px;
  padding-bottom: 14px;
  padding-left: 6px;
  margin-right: 3px;
  border: 1px solid;
}
.user-status {
  position: absolute !important;
  right: 72px;
  top: 9px;
}
.user-status .ant-badge-status-text {
  font-size: 10px;
}
.sb-avatar__text {
  border-radius: 2px;
}
.light-green-grey {
  .ant-collapse-content,
  .ant-collapse-header {
    background-color: #f0f2f5 !important;
  }
}
.ant-calendar-picker {
  width: 350px !important;
}
.card-padding {
  .ant-card-body {
    padding: 10px;
  }
}
.white_table {
  .ant-descriptions-view {
    border: none !important;
  }
  table {
    tr {
      border: none !important;
      th {
        padding: 5px 9px !important;
        background-color: #fff !important;
        border-right: 1px solid #fff !important;
      }
      td {
        padding: 5px 9px !important;
        background-color: #fff !important;
        border-right: 1px solid #fff !important;
      }
      td:before {
        content: ":";
      }
    }
  }
}
.text-bold {
  font-weight: 600;
}
.periods-before::before {
  content: ":";
}
.divider-text {
  span {
    font-size: 1.3em;
    font-weight: 400;
  }
}

.nested-child thead {
  display: none;
  tr td {
    background-color: aliceblue;
  }
}
.nested-child > div > div > div > div > div > table > tbody tr td {
  background-color: aliceblue !important;
}
.svg-primary svg {
  fill: #ff8800 !important;
}

.site-badge-count-4 .ant-badge-count {
  background-color: #fff;
  color: #999;
  box-shadow: 0 0 0 1px #d9d9d9 inset;
}
iframe {
  height: 100%;
  width: 100%;
}
.user-comment .ant-spin-nested-loading {
  overflow-y: scroll;
  max-height: 200px;
}
