.ant-menu {
  .anticon {
    font-size: 15px !important;
  }
  .MuiSvgIcon-root {
    font-size: 18px !important;
  }
}
.ant-table-thead > tr > th {
  font-weight: 600;
}
.no-style-tab .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #fff !important;
}
