.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.leadCard {
  padding: 5px;
  margin-bottom: 24px;
  cursor: pointer;
  border-radius: 1cap;

  .iconWarp {
    font-size: 54px;
    float: left;
  }

  .content {
    width: 100%;
    padding-left: 78px;

    .title {
      line-height: 16px;
      font-size: 16px;
      margin-bottom: 8px;
      height: 16px;
      .text-overflow();
    }
    .subtitle {
      font-size: 12px;
      font-style: italic;
    }

    .lead {
      line-height: 32px;
      font-size: 24px;
      height: 32px;
      .text-overflow();
      margin-bottom: 0;
    }
  }
}
