@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.compact.less";
@import "./style/main.less";
@primary-color: #0584bd;
@primary-1: color(~`colorPalette("@{primary-color}", 1) `);
@primary-2: color(~`colorPalette("@{primary-color}", 2) `);
@primary-3: color(~`colorPalette("@{primary-color}", 3) `);
@logo-color: #424242;
@body-background: #eff2f5;
@base-color: #fff;
@font-family: "Roboto", "Helvetica", "Arial", sans-serif;
@layout-header-background: @base-color;
@menu-bg: @base-color;
@layout-header-height: 43px;
@menu-inline-toplevel-item-height: 45px;
@menu-item-height: 40px;
@tabs-horizontal-padding: 8px 20px;
// FONT SIZE
@font-size-base: 14px;
@menu-item-font-size: 15px;
@table-font-size: 13px;
// TABLE
@table-header-color: @primary-color;
@table-header-bg: @primary-color;
@table-header-color: @base-color;
@table-row-hover-bg: @primary-1;
@table-selected-row-bg: @primary-1;
@table-border-color: @primary-color;
@page-header-back-color: @primary-color;

.ant-tabs-tab-active {
  background-color: @primary-1;
}

.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 4px;
}
.text-primary {
  color: @primary-color;
}
.ant-page-header-heading-title {
  color: @primary-color;
}

.base-style {
  background-color: @base-color;
}
.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #f2f2f2;
}
.theme-table tr:nth-child(odd) {
  background-color: #eff2f5;
}
.tab-shadow > div {
  box-shadow: 0px 5px 5px #dcd8d8;
}
.bg-primary {
  background-color: @primary-color;
}
